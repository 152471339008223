import React from "react";
import ExperienceItem from "./ExperienceItem";
import Revature from "../../../../images/revature-2.png"
import Walmart from "../../../../images/walmart.jpg"
import Roblox from "../../../../images/Roblox-Logo-2015-2017.png"
import YouTube from "../../../../images/youtube-video-logo-png-4.png"
import PokemonBrickBronze from "../../../../images/pokemon_brick_bronze_logo_summer_2017_remake_by_realmrbobbilly_dfa08lr.png"


const experienceData = [
    {
        id: 4,
        title: "Pokemon Brick Bronze",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • Project Bronze is a non-profit project that aims to revive a legacy experience on the Roblox.com platform, with inspirations from pocket monster catching games. Experience averages over <b>10,000+</b> concurrently playing users and has over <b>500,000+</b> community members.

                </p>
                <p style={{ textIndent: "1rem" }}>
                    <b>Short summaries of major contribution includes, but not limited to:</b>
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Developed and implemented a custom database wrapper using Google Firebase Realtime Database that mimics Roblox’s DataStore API, leading to over <b>10M+ players</b> progress being stored off-platform as a reliable data storage that serves over <b>80,000+</b> daily active users while also achieving <b>99% data reliability</b> as a fallback system.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Developed comprehensive monster mechanic system that supports <b>900+ unique variations</b>, including evolutions, complex battle data calulations, and form changes to simplify monster information system.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Successfully ported Showdown’s JavaScript codebase to Roblox Lua to streamline the game’s battle engine.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Served as project manager for 2 environmental artists to develop open-world regions for the successful delivery of major map expansions which includes, but not limited to, Cosmeos Valley, Port Decca, and Victory Road, leading to a <b>20,000+ player base increase</b>.
                </p>

            </>
        ),
        name: "Software Engineer",
        date: "May 2019 - Present",
        logo: PokemonBrickBronze,

    },
    {
        id: 2,
        title: "YouTube",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • YouTube creator with a total of over <b>3 million subscribers</b> creating kid-friendly content primarily on the Roblox platform, with an average of over <b>500,000+ views per month</b> and <b>75% average view duration</b>.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Utilized Tubebuddy and VidIQ for analytics and search ranking strategies to help optimize video results for the YouTube algorithm, consistently leading videos to be ranked in the top 10 of their respective niches.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Used Adobe Photoshop, After Effects, and Vegas Pro to produce engaging content and design attractive thumbnails, leading to an average of <b>10% click-through ratio per million views</b> and maintaining <b>60% audience retention</b> for videos lasting over 10 minutes.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Partnered creator with Union for Gamers MCN for creator networking opportunities, leading to sponsorship from companies such as OPRewards, Jazwares, and Swagbucks to maximize channel profitability and promote community engagement.
                </p>
            </>
        ),
        name: "Content Creator",
        date: "October 2015 - August 2024",
        logo: YouTube

    },
    {
        id: 1,
        title: "Walmart",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • My time here was to collect money for student loans.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Worked freights from the backroom and zone and stock inventory in the electronics department. Ocassionally work in other departments when needed.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Learned how to provide customer service, communication with team members, and help customers find their products.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Transferable skills include sales, customer support, communication, team work, and problem-solving skills.
                </p>
            </>
        ),
        name: "Electronics Associate",
        date: "May 2023 - August 2023",
        logo: Walmart,

    },
];
const Experience = () => {
    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap ">
                {experienceData.map((experience, id) => (
                    <ExperienceItem experience={experience} key={id} />
                ))}
            </div>
        </section>
    );
};

export default Experience;